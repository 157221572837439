/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {privateRoutes, publicRoutes} from "../../routes";
import {RouteNames} from "../../types/Route";
import {Box} from "@mui/material";
import {useTypeSelector} from "../../hooks/useTypeSelector";
import {useActions} from "../../hooks/useActions";
import Loader from "../UI/Loader/Loader";
import Layout from "../UI/Layout/Layout";

const AppRouter: FC = () => {
    const search = window.location.search.substring(1);
    const {auth, loading} = useTypeSelector(state => state.auth)
    const {invoiceId} = useTypeSelector(state => state.bindPaymentRequest)
    const {setIsAuth, setLoading, fetchCurrencyList, setBindPaymentRequest} = useActions();

    useEffect(() => {
        fetchCurrencyList();
        if (localStorage.getItem("auth")) {
            setIsAuth(true)
        }
        if (search.includes("invoiceId")) {
            let params = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value)
            })
            setBindPaymentRequest(params.invoiceId)
            localStorage.setItem("invoiceId", params.invoiceId)
        }
        setLoading(false)
    }, [])

    if (loading) {
        return <Loader/>
    }

    return (
        auth
            ?
            <Layout>
                <Switch>
                    {privateRoutes.map(route =>
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    )}
                    {
                        search.includes("invoiceId" || invoiceId)
                            ? <Redirect to={RouteNames.PAYMENT_REQUEST}/>
                            : <Redirect to={RouteNames.BALANCE}/>
                    }
                </Switch>
            </Layout>
            :
            <Box
                component={"main"}
                sx={{
                    height: "100vh",
                    padding: "0"
                }}
            >
                <Switch>
                    {publicRoutes.map(route =>
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    )}
                    <Redirect to={RouteNames.LOGIN}/>
                </Switch>
            </Box>
    );
};

export default AppRouter;
/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, SyntheticEvent, useEffect, useState} from 'react';
import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";
import TitleMain from "../components/UI/TitleMain";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {Colors, Fonts, ModalStyle} from "../utils/constants";
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
    Fade,
    Typography,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Snackbar,

} from "@mui/material";
import EPI from "../image/currency/eupi_symbol.svg";
import BTC from "../image/currency/bitcoin_symbol.svg";
import ETH from "../image/currency/eth_symbol.svg";
import EVER from "../image/currency/ever_symbol.svg";
import OILIK from "../assets/img/oilik.svg";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import Loader from "../components/UI/Loader/Loader";
import useHeightScreen from "../hooks/useHeightScreen";
import {IHistory} from "../model/IHistory";
import moment from "moment";
import {toFixed} from "../utils/validation";

const History: FC = () => {
    const screenHeight = useHeightScreen();
    const {responseHistory, error, loading} = useTypeSelector(state => state.history);
    const {user} = useTypeSelector(state => state.user);
    const userError = useTypeSelector(state => state.user.error);
    const {fetchHistory} = useActions();
    const [currency, setCurrency] = useState("All")
    const [navigationValue, setNavigationValue] = useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openModal, setOpenModal] = React.useState(false);
    const [modalData, setModalData] = React.useState({
        type: "",
        status: "",
        id: "",
        from: "",
        to: "",
        txId: "",
        note: "",
        amount: 0,
        total: 0,
        currency: "",
        fee: 0,
        paymentSystem: "",
        createdAt: 0,
        updatedAt: 0
    });
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleCloseModal = () => {
        setModalData({
            type: "",
            status: "",
            id: "",
            from: "",
            to: "",
            txId: "",
            note: "",
            amount: 0,
            total: 0,
            currency: "",
            fee: 0,
            paymentSystem: "",
            createdAt: 0,
            updatedAt: 0
        });
        setOpenModal(false);
    };

    const handleOpenModal = (data: IHistory) => {
        setModalData(data)
        setOpenModal(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        fetchHistory(currency)
    }, [currency])

    if (error || userError) {
        return <Typography>{error || userError}</Typography>
    }

    return (

        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
        >
            <Snackbar
                autoHideDuration={1000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                message="Hash copied to clipboard"
            />
            <TitleMain>
                HISTORY
            </TitleMain>
            <Typography
                variant="h2"
                fontSize={18}
                fontWeight={700}
                marginBottom={3}
                color={Colors.textSecondary}
            >
                Info about all your transactions
            </Typography>
            <Box
                display="flex"
                justifyContent="flex-start"
                marginBottom={3}
                alignItems="center"
            >
                <BottomNavigation
                    sx={{'.MuiBottomNavigation-root': {color: Colors.primary}}}
                    value={navigationValue}
                    onChange={(event: SyntheticEvent, newValue) => {
                        setCurrency(event.currentTarget.id)
                        setNavigationValue(newValue);
                    }}
                >
                    <BottomNavigationAction
                        sx={{
                            minWidth: 60
                        }}
                        label="All"
                        icon={<AccountBalanceWalletOutlinedIcon/>}
                    />
                    {user.balanceList.map((wallet, index) =>
                        <BottomNavigationAction
                            key={index}
                            sx={{
                                minWidth: 105
                            }}
                            id={wallet.currency}
                            label={wallet.name}
                            icon={
                                <img src={wallet.currency === "EPI"
                                    ? EPI
                                    : wallet.currency === "BTC"
                                        ? BTC
                                        : wallet.currency === "ETH"
                                            ? ETH
                                            : wallet.currency === "EVER"
                                                ? EVER
                                                : wallet.currency === "OLK"
                                                    ? OILIK
                                                    : "#"

                                }
                                     alt={wallet.name}
                                     style={{width: 20, height: 20}
                                     }
                                />
                            }
                        />
                    )}
                </BottomNavigation>
            </Box>
            <Box
                sx={{width: '100%', overflow: 'hidden'}}
            >
                <Paper sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer
                        sx={screenHeight < 450 ? {maxHeight: 350, minHeight: 350} : {maxHeight: 500, minHeight: 500}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{minWidth: 100, backgroundColor: Colors.tableHeader}}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        style={{minWidth: 100, backgroundColor: Colors.tableHeader}}
                                    >
                                        Type
                                    </TableCell>
                                    <TableCell
                                        style={{minWidth: 100, backgroundColor: Colors.tableHeader}}
                                    >
                                        Date
                                    </TableCell>
                                    <TableCell
                                        style={{minWidth: 150, backgroundColor: Colors.tableHeader}}
                                    >
                                        Credit
                                    </TableCell>
                                    <TableCell
                                        style={{minWidth: 150, backgroundColor: Colors.tableHeader}}
                                    >
                                        Debit
                                    </TableCell>
                                    <TableCell
                                        style={{minWidth: 100, backgroundColor: Colors.tableHeader}}
                                    >
                                        Fee
                                    </TableCell>
                                    {/*<TableCell*/}
                                    {/*    style={{minWidth: 100, backgroundColor: Colors.tableHeader}}*/}
                                    {/*>*/}
                                    {/*    PS*/}
                                    {/*</TableCell>*/}
                                    <TableCell
                                        style={{minWidth: 100, backgroundColor: Colors.tableHeader}}
                                    >
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading
                                    ? <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                paddingY: 25,
                                                position: "relative"
                                            }}
                                        >
                                            <Loader
                                                left="50%"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    :
                                    responseHistory
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                    <TableCell
                                                        sx={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={handleOpenModal.bind(null, row)}
                                                    >
                                                        {String(row.id).substr(0, 4) + " ... " + String(row.id).substr(String(row.id).length - 4, String(row.id).length)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(row.createdAt).format("DD MMM YYYY HH:hh")}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{color: Colors.TableCredit}}
                                                    >
                                                        {row.amount > 0 && toFixed(row.amount) + ` ${row.currency}`}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{color: Colors.TableDebit}}
                                                    >
                                                        {row.amount < 0 && toFixed(row.amount) + ` ${row.currency}`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {toFixed(row.fee) + ` ${row.currency}`}
                                                    </TableCell>
                                                    {/*<TableCell>*/}
                                                    {/*    {row.paymentSystem}*/}
                                                    {/*</TableCell>*/}
                                                    <TableCell>
                                                        {row.status}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {loading
                        ? null
                        :
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={responseHistory.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    }
                </Paper>
            </Box>

            {/*HISTORY MODAL===============================================================================================*/}
            <Modal
                aria-labelledby="history-info-modal-title"
                aria-describedby="history-info-modal-description"
                open={openModal}
                onClose={handleOpenModal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade
                    in={openModal}
                >
                    <Box sx={ModalStyle} minWidth={500}>
                        <Typography
                            id="history-info-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            {`Transaction: ${modalData.id ? modalData.id : "N/A"} `}
                        </Typography>
                        <Typography
                            id="history-info-modal-date"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Date of transaction: ${modalData.createdAt ? moment(modalData.createdAt).format("DD MMM YYYY HH:hh") : "N/A"} `}
                        </Typography>
                        <Typography
                            id="history-info-modal-from"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`From: ${modalData.from ? modalData.from : "N/A"}`}
                        </Typography>
                        <Typography
                            id="history-info-modal-to"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`To: ${modalData.to ? modalData.to : "N/A"}`}
                        </Typography>
                        <Typography
                            id="history-info-modal-note"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Note: ${modalData.note ? modalData.note : "N/A"}`}
                        </Typography>
                        <Typography
                            id="history-info-modal-hash"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all", cursor: "pointer"}}
                            onClick={() => {
                                navigator.clipboard.writeText(modalData.txId ? modalData.txId : "N/A")
                                setOpenSnackBar(true)
                            }}
                        >
                            {`Hash: `} <ContentCopyOutlinedIcon
                            sx={{fontSize: 12}}/> {modalData.txId ? modalData.txId : "N/A"}
                        </Typography>
                        <Typography
                            id="history-info-modal-type"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Type: ${modalData.type ? modalData.type : "N/A"}`}
                        </Typography>
                        {/*<Typography*/}
                        {/*    id="history-info-modal-payment-system"*/}
                        {/*    variant="body2"*/}
                        {/*    color={Colors.textPrimary}*/}
                        {/*    marginBottom={1}*/}
                        {/*    sx={{wordBreak: "break-all"}}*/}
                        {/*>*/}
                        {/*    {`Payment system: ${modalData.paymentSystem ? modalData.paymentSystem : "N/A"}`}*/}
                        {/*</Typography>*/}
                        <Typography
                            id="history-info-modal-status"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Status: ${modalData.status ? modalData.status : "N/A"}`}
                        </Typography>
                        <Typography
                            id="history-info-modal-amount"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={3}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Amount: `}
                            <span className={modalData.currency === "EPI"
                                ? "icon-eupi_symbol"
                                : modalData.currency === "BTC"
                                    ? "icon-bitcoin_symbol"
                                    : modalData.currency === "ETH"
                                        ? "icon-eth_symbol"
                                        : modalData.currency === "EVER"
                                            ? "icon-ever_symbol"
                                        : modalData.currency === "OLK" ? "icon-OILIK_symbol"
                                            : ""
                            }
                                  style={{
                                      fontSize: 11,
                                      fontFamily: "Icon"
                                  }}/> {modalData.amount ? modalData.amount : "N/A"}
                        </Typography>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleCloseModal}
                        >
                            CLOSE
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default History;
import {useEffect, useState} from 'react';

const useWidthScreen = () => {

    const [width, setWidth] = useState(window.screen.availWidth);

    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.screen.availWidth;
            setWidth(newWidth);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);
    return width
};

export default useWidthScreen;
export enum Colors {
    bgColorPaper = '#F4F6F9',
    paymentListTitle = '#66AFB6',
    primary = '#50AF9A',
    TableCredit = '#77c46f',
    TableDebit = '#ff5959',
    secondary = '#858585',
    tableHeader = '#e9e7e7',
    textPrimary = '#252525',
    textInfo = '#68AD9B',
    textSecondary = '#A1A1A1',
    buttonTextSecondary = '#ffffff',
}

export const ModalStyle = {
    position: `absolute` as `absolute`,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export const ModalStyleRegister = {
    position: `absolute` as `absolute`,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export const RadioButtonStyle = {
    color: Colors.secondary,
    '&.Mui-checked': {
        color: Colors.primary,
    },
}
export const TextFieldStyle = {
    marginBottom: 3,
    '& label.Mui-focused': {
        color: Colors.primary,
    },
    '& .MuiOutlinedInput-root': {

        '& fieldset': {
            borderColor: Colors.secondary,
        },
        '&:hover fieldset': {
            borderColor: Colors.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: Colors.primary,
        },
    },
}

export enum Fonts {
    intro = "Intro"
}


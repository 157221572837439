import {AuthActions, AuthActionTypes, IAuthState} from "../../../types/User/Auth";

const initialState: IAuthState = {
    noValidate: false,
    auth: false ,
    OTPSent: false,
    status: 0,
    token: "",
    phone: "",
    password: "",
    errorOTP: null,
    errorAuth: null,
    loading: true,
}

export const authReducer = (state = initialState, action: AuthActions): IAuthState => {
    switch (action.type) {
        case AuthActionTypes.SET_AUTH:
            return {...state, loading: false,auth: action.payload};
        case AuthActionTypes.SET_LOADING:
            return {...state, loading: action.payload};
        case AuthActionTypes.SET_PHONE:
            return {...state, phone: action.payload, errorOTP: null};
        case AuthActionTypes.SET_PASSWORD:
            return {...state, password: action.payload, errorAuth: null};
        case AuthActionTypes.SET_NO_VALIDATE:
            return {...state, noValidate: action.payload};
        case AuthActionTypes.FETCH_OTP:
            return {...state, errorOTP: null, noValidate: false};
        case AuthActionTypes.FETCH_OTP_SUCCESS:
            return {...state, loading: false, OTPSent: true};
        case AuthActionTypes.FETCH_OTP_ERROR:
            return {...state, loading: false, errorOTP: action.payload, OTPSent: false};
        case AuthActionTypes.FETCH_AUTH:
            return {...state, errorAuth: null};
        case AuthActionTypes.FETCH_AUTH_SUCCESS:
            return {...state, loading: false, auth: true, token: action.payload, OTPSent: false, errorAuth: null, errorOTP: null, status: 0, phone: "", password: ""};
        case AuthActionTypes.FETCH_AUTH_ERROR:
            return {...state, loading: false, errorAuth: action.payload,};
        default:
            return state
    }
}
import React, {FC, useState} from 'react';
// import axios from "axios";
import googlePlay from "../assets/img/google-play.svg";
import appStore from "../assets/img/app-store.svg";
import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";

import {phoneValid} from "../utils/validation";
// import {config} from "../utils/config";
import useWidthScreen from "../hooks/useWIdthScreen";
import {Colors, Fonts, ModalStyle, ModalStyleRegister} from "../utils/constants";

import {
    Box, Button, Fade,
    IconButton,
    InputAdornment,
    Link, Modal, TextField,
    Typography
} from "@mui/material";

import {Visibility, VisibilityOff} from "@mui/icons-material";
// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LogoWelcome from "../image/logo/logo.svg"
// import TermsOfUse from "../docs/Terms_of_Use_revised.pdf";
// import CookiePolice from "../docs/COOKIE_POLICY.pdf";
// import AMLPolice from "../docs/AML_policy.pdf";
// import PersonalOfDataStoragePolice from "../docs/Personal_data_storage_policy.pdf";

const Login: FC = () => {
    const width = useWidthScreen();
    const [showPassword, setShowPassword] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const {errorAuth, errorOTP, OTPSent, phone, password, noValidate} = useTypeSelector(state => state.auth);
    const {invoiceId} = useTypeSelector(state => state.bindPaymentRequest);
    const {fetchOTPPassword, fetchAuth, setPhone, setPassword, setValidate} = useActions();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleCloseModalError = () => {
        setValidate(false);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const sendOTP = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        fetchOTPPassword(phone);
    };

    const submitAuth = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (invoiceId) {
            fetchAuth(phone, password, invoiceId);
        } else {
            fetchAuth(phone, password);
        }
    };

    const handleCloseModal = () => {
        setShowRegisterModal(false)
    };

    const goToReg = async () => {
        // if (invoiceId) {
        //     const response = await axios.get(`acquiring/goToReg/${invoiceId}`, {baseURL: config.url.API_ACQUIRING})
        //     if (response.data.status === 0) {
        //         window.open(config.url.API_REG + `?partner=evercash&id=${invoiceId}`, "_blank");
        //     }
        // } else {
        //     window.open(config.url.API_REG + `?partner=evercash`, "_blank");
        // }
        setShowRegisterModal(true)
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "phone") {
            if (phoneValid(event.target.value) || event.target.value === "") {
                setPhone(event.target.value)
            }
        }
        if (event.target.name === "password") {
            setPassword(event.target.value)
        }

    };

    return (
        <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingY={5}
        >
            <Box
                component="div"
                marginBottom={3}
            >
                <img src={LogoWelcome} style={{width: "46px", height: "46px"}} alt="Welcome to evercash"/>
            </Box>

            <Box
                maxWidth={600}
                boxShadow={3}
                paddingY={5}
                paddingX={width > 500 ? 10 : 5}
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginBottom={3}
            >
                <Typography
                    variant="h1"
                    fontSize={22}
                    textAlign="center"
                    fontFamily={Fonts.intro}
                    marginBottom={5}
                >
                    Welcome to Evercash
                </Typography>

                <Typography
                    variant="h5"
                    fontSize={16}
                    textAlign="center"
                    marginBottom={3}
                >
                    Sign in to your account
                </Typography>
                <TextField
                    fullWidth
                    autoFocus
                    sx={{
                        marginBottom: 3,
                        '& label.Mui-focused': {
                            color: Colors.primary,
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: Colors.secondary,
                            },
                            '&:hover fieldset': {
                                borderColor: Colors.secondary,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: Colors.primary,
                            },
                        },
                    }}
                    error={!!errorOTP}
                    label="Phone"
                    type="tel"
                    id="outlined-adornment-amount"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    helperText={errorOTP}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+</InputAdornment>,
                    }}
                />

                <Button
                    variant="contained"
                    sx={{
                        width: "25ch"
                    }}
                    fullWidth={width <= 500}
                    onClick={sendOTP}
                >
                    Sign in
                </Button>
            </Box>

            <Box
                component="div"
                display="flex"
                justifyContent="center"
                marginBottom={0.5}
            >
                <Typography
                    color={Colors.textSecondary}
                    fontSize={14}

                >
                    New user? <Link
                    sx={{
                        cursor: "pointer"
                    }}
                    target="_blank"
                    onClick={goToReg}
                    color={Colors.primary}
                    fontSize={14}
                >
                    Register
                </Link>
                </Typography>
            </Box>
            <Box
                component="div"
                display="flex"
                justifyContent="center"
                marginBottom={3}
            >
                <Link
                    sx={{
                        cursor: "pointer"
                    }}
                    href={"https://evercash.eu/business/"}
                    target="_blank"
                    color={Colors.primary}
                    fontSize={14}
                >
                    Сreate a&nbsp;business account
                </Link>
            </Box>

            <Box
                component="div"
                display="flex"
                justifyContent="center"
                marginBottom={3}
                maxWidth={480}

            >
                <Typography
                    color={Colors.textSecondary}
                    fontSize={14}
                    lineHeight={1}
                >
                    &copy;️2019&mdash;2021&nbsp;BERTES INVEST&nbsp;OU (reg. No&nbsp;12449024) is&nbsp;registered
                    in&nbsp;Estonia with its registration address at&nbsp;Harju maakond, Tallinn, Kesklinna linnaosa,
                    Veskiposti tn&nbsp;2, 10138, Estonia. Bertes Invest OU&nbsp;operates under Financial services,
                    Providing a&nbsp;virtual currency services license (license number: FVT000291), issued
                    by&nbsp;Estonian Financial Intelligence Unit. Contact phone number: +372&nbsp;601 9100;
                    e-mail: <Link
                    href="mailto: support@evercash.eu"
                    color={Colors.primary}
                >support@evercash.eu
                </Link>
                </Typography>
            </Box>

            {/*<Box*/}
            {/*    width="100%"*/}
            {/*    display="flex"*/}
            {/*    justifyContent="space-between"*/}
            {/*>*/}
            {/*    <Link*/}
            {/*        href={TermsOfUse}*/}
            {/*        target="_blank"*/}
            {/*        color={Colors.textSecondary}*/}
            {/*        fontSize={14}*/}
            {/*        sx={{*/}
            {/*            "&:hover": {*/}
            {/*                textDecorationColor: Colors.primary,*/}
            {/*                color: Colors.primary,*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Terms of Use*/}
            {/*    </Link>*/}
            {/*    <Link*/}
            {/*        href={CookiePolice}*/}
            {/*        target="_blank"*/}
            {/*        color={Colors.textSecondary}*/}
            {/*        fontSize={14}*/}
            {/*        sx={{*/}
            {/*            "&:hover": {*/}
            {/*                textDecorationColor: Colors.primary,*/}
            {/*                color: Colors.primary,*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Cookie police*/}
            {/*    </Link>*/}
            {/*    <Link*/}
            {/*        href={AMLPolice}*/}
            {/*        target="_blank"*/}
            {/*        color={Colors.textSecondary}*/}
            {/*        fontSize={14}*/}
            {/*        sx={{*/}
            {/*            "&:hover": {*/}
            {/*                textDecorationColor: Colors.primary,*/}
            {/*                color: Colors.primary,*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        AML policy*/}
            {/*    </Link>*/}
            {/*    <Link*/}
            {/*        href={PersonalOfDataStoragePolice}*/}
            {/*        target="_blank"*/}
            {/*        color={Colors.textSecondary}*/}
            {/*        fontSize={14}*/}
            {/*        sx={{*/}
            {/*            "&:hover": {*/}
            {/*                textDecorationColor: Colors.primary,*/}
            {/*                color: Colors.primary,*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Personal Data storage policy*/}
            {/*    </Link>*/}
            {/*</Box>*/}
            <Modal
                aria-labelledby="confirm-modal-title"
                aria-describedby="confirm-modal-description"
                open={OTPSent}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={OTPSent}>
                    <Box sx={ModalStyle}>
                        {/*<CloseOutlinedIcon/>*/}
                        <Typography
                            id="confirm-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            Enter confirm code
                        </Typography>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <TextField
                                fullWidth
                                autoFocus
                                sx={{
                                    marginBottom: 3,
                                    '& label.Mui-focused': {
                                        color: Colors.primary,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: Colors.secondary,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: Colors.secondary,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: Colors.primary,
                                        },
                                    },
                                }}
                                error={!!errorAuth}
                                label="Confirm code"
                                type={showPassword ? 'text' : 'password'}
                                id="outlined-adornment-amount"
                                name="password"
                                value={password}
                                onChange={handleChange}
                                helperText={errorAuth}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />

                            <Box
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Button
                                    // fullWidth={width <= 500}
                                    variant="contained"
                                    // onClick={}
                                >
                                    Resend
                                </Button>
                                <Button
                                    // fullWidth={width <= 500}
                                    variant="contained"
                                    onClick={submitAuth}
                                >
                                    Confirm
                                </Button>
                            </Box>

                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="register-modal-title"
                aria-describedby="register-modal-description"
                open={showRegisterModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showRegisterModal}>
                    <Box sx={ModalStyleRegister}>
                        <Typography
                            id="confirm-modal-title"
                            variant="h6"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            Please register in mobile app.
                        </Typography>
                        <Box
                            display={"flex"}
                            justifyContent={"space-around"}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <Link
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                    href={"https://play.google.com/store/apps/details?id=com.evercash.mobile"}
                                    download
                                    color={Colors.primary}
                                >
                                    <img src={googlePlay} alt="Google play market"/>
                                </Link>
                                <Typography
                                    fontSize={12}
                                >
                                    <Link
                                        sx={{
                                            cursor: "pointer"
                                        }}
                                        href={"https://play.google.com/store/apps/details?id=com.evercash.mobile"}
                                        download
                                        color={Colors.primary}
                                    >
                                        Install app
                                    </Link>
                                </Typography>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <Link
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                    href={"https://apps.apple.com/au/app/evercash/id1620563575"}
                                    target="_blank"
                                    color={Colors.primary}
                                >
                                    <img src={appStore} alt="Apple store"/>
                                </Link>
                                <Typography
                                    fontSize={12}
                                >
                                    <Link
                                        sx={{
                                            cursor: "pointer"
                                        }}
                                        href={"https://apps.apple.com/au/app/evercash/id1620563575"}
                                        download
                                        color={Colors.primary}
                                    >
                                        Install app
                                    </Link>
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="notApprove-modal-title"
                aria-describedby="notApprove-modal-description"
                open={noValidate}
                closeAfterTransition
                onClose={handleCloseModalError}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={noValidate}>
                    <Box sx={ModalStyle}>
                        <Typography
                            id="notApprove-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            {errorOTP}
                        </Typography>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Typography
                                variant="body1"
                                fontWeight={700}
                                fontSize={14}
                                color={Colors.textPrimary}
                                marginBottom={3}
                            >
                                KYC will take some time. If the verification is not passed within 24 hours, contact the support service. <Link
                                href="mailto: support@evercash.eu"
                                color={Colors.primary}
                            >
                                support@evercash.eu
                            </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default Login;
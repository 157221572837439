/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {
    Box, Button, Fade,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    MenuItem, Modal,
    Paper, Radio, RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Colors, Fonts, ModalStyle, RadioButtonStyle, TextFieldStyle} from "../utils/constants";
import {useTypeSelector} from "../hooks/useTypeSelector";
import BTC from "../image/currency/BTC.png";
import ETH from "../image/currency/ETH.png";
import EPI from "../image/currency/EPI.png";
import EVER from "../image/currency/EVER.png";
import OILIK from "../assets/img/oilik.svg";
import TitleMain from "../components/UI/TitleMain";
import {useActions} from "../hooks/useActions";
import {positiveNumberValid, toFixed} from "../utils/validation";
import useWidthScreen from "../hooks/useWIdthScreen";

const Exchange: FC = () => {
        const currency = "ETH";
        const {error, user} = useTypeSelector(state => state.user);
        const {dataExchange, responseExchange, loading} = useTypeSelector(state => state.exchange);
        const errorExchange = useTypeSelector(state => state.exchange.error);
        const {responseRate} = useTypeSelector(state => state.exchangeRate);
        const errorRate = useTypeSelector(state => state.exchangeRate.error);
        const {
            setExchangeData,
            fetchExchange,
            clearExchangeData,
            fetchExchangeRate,
            clearRate,
            fetchBalance
        } = useActions();
        const [openModalOrder, setOpenModalOrder] = useState(false);
        const width = useWidthScreen();

        const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
            setExchangeData({...dataExchange, direction: event.target.value})
        }
        const submit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            fetchExchange(dataExchange)
        }

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.name === "amount") {
                if (positiveNumberValid(event.target.value)) {
                    setExchangeData({...dataExchange, amount: event.target.value})
                }
            } else {
                setExchangeData({...dataExchange, [event.target.name]: event.target.value})
            }
        };
        const handleOpenModal = () => {
            setOpenModalOrder(true);
        };

        const handleCloseModal = () => {
            setOpenModalOrder(false);
            clearExchangeData();
            clearRate();
            fetchBalance();
        };

        useEffect(() => {
            if (Number(dataExchange.amount) > 0 && dataExchange.currency) {
                fetchExchangeRate({amount: Number(dataExchange.amount), from: dataExchange.currency, to: "EPI"})
            }
        }, [dataExchange]);

        useEffect(() => {
            if (responseExchange.operation) {
                handleOpenModal();
            }
        }, [responseExchange.operation]);

        useEffect(() => {
            if (currency) {
                clearRate();
                setExchangeData({...dataExchange, currency: currency})
            } else {
                clearExchangeData();
                clearRate();
            }
        }, []);

        if (error || errorExchange || errorRate) {
            return <Typography>{error || errorExchange || errorRate}</Typography>
        }
        return (
            <Box
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
            >
                <TitleMain>
                    EXCHANGE
                </TitleMain>
                <Typography
                    variant="h2"
                    fontSize={18}
                    fontWeight={700}
                    marginBottom={3}
                    color={Colors.textSecondary}
                >
                    You can exchange funds within your accounts
                </Typography>
                <Stack
                    direction="row"
                    justifyContent="center"
                    flexWrap={"wrap"}
                    alignItems="stretch"
                    spacing={0}
                >
                    <Paper
                        variant="outlined"
                        sx={{
                            ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                            position: "relative",
                            padding: 3
                        }}
                    >
                        <Box
                            display="flex"
                            marginBottom={3}
                        >
                            <img src={EPI} style={{width: 36, height: 36, marginRight: 4}} alt="EuPI"/>
                            {user.balanceList.filter(wallet => wallet.currency === "EPI").map((balance, index) =>
                                <Box
                                    key={index}
                                >
                                    <Typography

                                        fontSize={12}
                                        color={Colors.textSecondary}
                                    >
                                        {`${balance.name} balance`}
                                    </Typography>
                                    <Typography

                                        fontSize={12}
                                        color={Colors.textPrimary}
                                    >
                                        {`${balance.balance}`}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box
                            width="100%"
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                            noValidate
                            onSubmit={submit}
                            autoComplete="off"
                        >
                            <FormControl component="fieldset" sx={{marginBottom: 2}}>
                                <FormLabel component="legend">Select operation</FormLabel>
                                <RadioGroup
                                    aria-label="operation"
                                    name="controlled-radio-buttons-group"
                                    value={dataExchange.direction}
                                    onChange={handleChangeChecked}
                                >
                                    <FormControlLabel value="BUY" control={<Radio sx={RadioButtonStyle}/>}
                                                      label={`Buy ${dataExchange.currency}`}/>
                                    <FormControlLabel value="SELL" control={<Radio sx={RadioButtonStyle}/>}
                                                      label={`Sell ${dataExchange.currency}`}/>
                                </RadioGroup>
                            </FormControl>
                            <TextField
                                id="currency"
                                name="currency"
                                select
                                fullWidth
                                sx={{...TextFieldStyle, '.MuiTextField-root': {width: "100%"}}}
                                style={{paddingLeft: 0}}
                                label="Select currency"
                                value={dataExchange.currency}
                                onChange={handleChange}
                            >
                                {user.balanceList.filter(balance => balance.currency !== "EPI").map((option, index) => (
                                    <MenuItem key={index} value={option.currency}>
                                        <Box sx={{display: "flex"}}>
                                            <img
                                                style={{maxWidth: 28, height: 28, marginRight: 4}}
                                                src={option.currency === "BTC"
                                                    ? BTC
                                                    : option.currency === "ETH"
                                                        ? ETH
                                                        : option.currency === "EVER"
                                                            ? EVER
                                                            : option.currency === "OLK"
                                                                ? OILIK
                                                                : ""
                                                }
                                                alt={option.name}
                                            />
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                            >
                                                <Typography fontSize={11}
                                                            color={Colors.textSecondary}>{`${option.name} balance`}</Typography>
                                                <Typography fontSize={12}>{option.balance}</Typography>
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                sx={TextFieldStyle}
                                error={!dataExchange.amount}
                                helperText={!dataExchange.amount && "Please enter amount."}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <span className={
                                            dataExchange.currency === "EPI"
                                                ? "icon-eupi_symbol"
                                                : dataExchange.currency === "BTC"
                                                    ? "icon-bitcoin_symbol"
                                                    : dataExchange.currency === "ETH"
                                                        ? "icon-eth_symbol"
                                                        : dataExchange.currency === "EVER"
                                                            ? "icon-ever_symbol"
                                                        : dataExchange.currency === "OLK" ? "icon-OILIK_symbol"
                                                            : ""
                                        } style={{fontSize: 15, fontFamily: "Icon"}}/>
                                    </InputAdornment>,
                                }}
                                label="Amount"
                                type="number"
                                id={`amount`}
                                name={`amount`}
                                placeholder={'0'}
                                value={dataExchange.amount}
                                onChange={handleChange}
                            />
                            <Typography
                                textAlign="left"
                                color={Colors.textPrimary}
                                paddingLeft={1}
                                marginY={3}
                            >
                                {`Rate: `}
                                <span
                                    className={"icon-eupi_symbol"}
                                    style={{fontSize: 14, fontFamily: "Icon"}}
                                /> {responseRate.rate}
                            </Typography>
                            <Typography
                                color={Colors.textPrimary}
                                paddingLeft={1}
                                marginBottom={3}
                            >
                                {`Credit: `}
                                <span
                                    className={"icon-eupi_symbol"}
                                    style={{fontSize: 14, fontFamily: "Icon"}}
                                /> {responseRate.credit}
                            </Typography>
                            <Button
                                variant={"contained"}
                                fullWidth
                                type="submit"
                                disabled={!(Number(dataExchange.amount) > 0) || loading}
                            >
                                Exchange
                            </Button>
                        </Box>
                        {width > 850 &&
                            <Box
                                position={"absolute"}
                                top={25}
                                right={"-90%"}
                            >
                                <Typography
                                    fontSize={12}
                                    color={Colors.textPrimary}
                                    mb={3}
                                >
                                    Money exchange to Evercash wallet
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color={Colors.textPrimary}
                                >
                                    Currency is exchanged on the currency exchange market in live mode
                                </Typography>
                                <Typography
                                    fontSize={12}
                                    color={Colors.textSecondary}
                                    mb={3}
                                >
                                    Floating exchange rate
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color={Colors.textPrimary}
                                >
                                    Instantly
                                </Typography>
                                <Typography
                                    fontSize={12}
                                    color={Colors.textSecondary}
                                >
                                    MTransfer term
                                </Typography>
                            </Box>
                        }
                    </Paper>
                    {width < 850 &&
                        <Paper
                            variant="outlined"
                            sx={{
                                ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                                padding: 3,
                            }}
                        >
                            <Typography
                                fontSize={12}
                                color={Colors.textPrimary}
                                mb={3}
                            >
                                Money exchange to Evercash wallet
                            </Typography>

                            <Typography
                                fontSize={12}
                                color={Colors.textPrimary}
                            >
                                Currency is exchanged on the currency exchange market in live mode
                            </Typography>
                            <Typography
                                fontSize={12}
                                color={Colors.textSecondary}
                                mb={3}
                            >
                                Floating exchange rate
                            </Typography>

                            <Typography
                                fontSize={12}
                                color={Colors.textPrimary}
                            >
                                Instantly
                            </Typography>
                            <Typography
                                fontSize={12}
                                color={Colors.textSecondary}
                            >
                                MTransfer term
                            </Typography>
                        </Paper>
                    }
                </Stack>
                {/*EXCHANGE ORDER MODAL===============================================================================================*/}
                <Modal
                    aria-labelledby="withdrawal-crypto-order-modal-title"
                    aria-describedby="withdrawal-crypto-order-modal-description"
                    open={openModalOrder}
                    onClose={handleOpenModal}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModalOrder}>
                        <Box sx={ModalStyle}>
                            <Typography
                                id="withdrawal-crypto-order-modal-title"
                                variant="h5"
                                color={Colors.textPrimary}
                                fontFamily={Fonts.intro}
                                marginBottom={3}
                            >
                                {`Exchange ${dataExchange.currency} order.`}
                            </Typography>
                            <Typography
                                id="exchange-order-modal-account-id"
                                variant="body2"
                                color={Colors.textPrimary}
                                marginBottom={1}
                                sx={{wordBreak: "break-all"}}
                            >
                                {`Account ID: ${responseExchange.accountId}`}
                            </Typography>
                            <Typography
                                id="exchange-order-modal-order-id"
                                variant="body2"
                                color={Colors.textPrimary}
                                marginBottom={1}
                                sx={{wordBreak: "break-all"}}
                            >
                                {`Order ID: ${responseExchange.id}`}
                            </Typography>
                            <Typography
                                id="withdrawal-crypto-order-modal-sent"
                                variant="body2"
                                color={Colors.textPrimary}
                                marginBottom={1}
                                sx={{wordBreak: "break-all"}}
                            >
                                {`Amount: `}
                                <span className={
                                    dataExchange.currency === "EPI"
                                        ? "icon-eupi_symbol"
                                        : dataExchange.currency === "BTC"
                                            ? "icon-bitcoin_symbol"
                                            : dataExchange.currency === "ETH"
                                                ? "icon-eth_symbol"
                                                : dataExchange.currency === "EVER"
                                                    ? "icon-ever_symbol"
                                                : dataExchange.currency === "OLK" ? "icon-OILIK_symbol"
                                                    : ""
                                } style={{fontSize: 12, fontFamily: "Icon"}}/> {toFixed(responseExchange.amount)}
                            </Typography>
                            <Typography
                                id="withdrawal-crypto-order--modal-title"
                                variant="body2"
                                color={Colors.textPrimary}
                                marginBottom={1}
                                sx={{wordBreak: "break-all"}}
                            >
                                {`Operation: ${responseExchange.operation}`}
                            </Typography>

                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleCloseModal}
                            >
                                ok
                            </Button>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        );
    }
;

export default Exchange;
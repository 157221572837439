/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {
    Box, Button, Fade,
    InputAdornment,
    MenuItem, Modal,
    Paper,
    Snackbar,
    Stack,
    TextField
} from "@mui/material";
import TitleMain from "../components/UI/TitleMain";
import Typography from "@mui/material/Typography";
import {Colors, Fonts, ModalStyle, TextFieldStyle} from "../utils/constants";
import EPI from "../image/currency/EPI.png";
import BTC from "../image/currency/BTC.png";
import ETH from "../image/currency/ETH.png";
import EVER from "../image/currency/EVER.png";
import OILIK from "../assets/img/oilik.svg";
import {useTypeSelector} from "../hooks/useTypeSelector";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {useActions} from "../hooks/useActions";
import {positiveNumberValid} from "../utils/validation";
import useWidthScreen from "../hooks/useWIdthScreen";

const Transfer: FC = () => {
    const width = useWidthScreen();
    const {user} = useTypeSelector(state => state.user);
    const errorUser = useTypeSelector(state => state.user.error);
    const {dataTransfer, error, responseTransfer} = useTypeSelector(state => state.transfer);
    const {setTransferData, clearTransferData, fetchTransfer} = useActions();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "amount") {
            if (positiveNumberValid(event.target.value)) {
                setTransferData({...dataTransfer, amount: event.target.value})
            }
        } else {
            setTransferData({...dataTransfer, [event.target.name]: event.target.value})
        }
    };

    const confirmTransfer = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        clearTransferData();
    };
    const sendTransfer = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        fetchTransfer(dataTransfer);
    };

    const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    useEffect(() => {
        clearTransferData();
    }, []);

    useEffect(() => {
        if (responseTransfer.message) {
            handleCloseModal()
        }
    }, [responseTransfer.message]);

    if (error || errorUser) {
        return <Typography>{error || errorUser}</Typography>
    }

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
        >
            <Snackbar
                autoHideDuration={1000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                message="ID account copied to clipboard"
            />
            <TitleMain>
                INTERNAL TRANSFER
            </TitleMain>
            <Typography
                maxWidth="fit-content"
                variant="h2"
                fontSize={18}
                fontWeight={700}
                marginBottom={3}
                color={Colors.textSecondary}
                sx={{
                    cursor: "pointer"
                }}
                onClick={() => {
                    navigator.clipboard.writeText(user.id ? user.id : "N/A")
                    setOpenSnackBar(true)
                }}
            >
                {`Your Evercash ID is: ${user.id} `}<ContentCopyOutlinedIcon sx={{fontSize: 18}}/>
            </Typography>
            <Stack
                direction="row"
                justifyContent="center"
                flexWrap={"wrap"}
                alignItems="stretch"
                spacing={0}
            >
                <Paper
                    sx={{
                        ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                        position: "relative",
                        padding: 3
                    }}
                >
                    <Box
                        width="100%"
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                        noValidate
                        onSubmit={confirmTransfer}
                        autoComplete="off"
                    >
                        <TextField
                            id="currency"
                            name="currency"
                            select
                            fullWidth
                            sx={{...TextFieldStyle, '.MuiTextField-root': {width: "100%"}}}
                            style={{paddingLeft: 0}}
                            label="Select currency"
                            value={dataTransfer.currency}
                            onChange={handleChangeData}
                        >
                            {user.balanceList.map((option, index) => (
                                <MenuItem key={index} value={option.currency}>
                                    <Box sx={{display: "flex"}}>
                                        <img
                                            style={{maxWidth: 28, height: 28, marginRight: 4}}
                                            src={option.currency === "EPI"
                                                ? EPI
                                                : option.currency === "BTC"
                                                    ? BTC
                                                    : option.currency === "ETH"
                                                        ? ETH
                                                        : option.currency === "EVER"
                                                            ? EVER
                                                            : option.currency === "OLK"
                                                                ? OILIK
                                                                : ""
                                            }
                                            alt={option.name}
                                        />
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography fontSize={11}
                                                        color={Colors.textSecondary}>{`${option.name} balance`}</Typography>
                                            <Typography fontSize={12}>{option.balance}</Typography>
                                        </Box>
                                    </Box>
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            fullWidth
                            sx={TextFieldStyle}
                            error={!!error}
                            helperText={error}
                            label="Account ID"
                            type="text"
                            id={`to`}
                            name={`to`}
                            value={dataTransfer.to}
                            onChange={handleChangeData}
                        />
                        <TextField
                            fullWidth
                            sx={TextFieldStyle}
                            error={!dataTransfer.amount}
                            helperText={!dataTransfer.amount && "Please enter amount."}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                        <span className={
                                            dataTransfer.currency === "EPI"
                                                ? "icon-eupi_symbol"
                                                : dataTransfer.currency === "BTC"
                                                    ? "icon-bitcoin_symbol"
                                                    : dataTransfer.currency === "ETH"
                                                        ? "icon-eth_symbol"
                                                        : dataTransfer.currency === "EVER"
                                                            ? "icon-ever_symbol"
                                                        : dataTransfer.currency === "OLK" ? "icon-OILIK_symbol"
                                                            : ""
                                        } style={{fontSize: 15, fontFamily: "Icon"}}/>
                                </InputAdornment>,
                            }}
                            placeholder={"0"}
                            label="Amount"
                            type="number"
                            id={`amount`}
                            name={`amount`}
                            value={dataTransfer.amount}
                            onChange={handleChangeData}
                        />
                        <TextField
                            fullWidth
                            sx={TextFieldStyle}
                            label="Note"
                            type="text"
                            id={`note`}
                            name={`note`}
                            value={dataTransfer.note}
                            onChange={handleChangeData}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                marginTop: 3
                            }}
                            type="submit"
                            disabled={!(Number(dataTransfer.amount) > 0)}
                        >
                            Transfer
                        </Button>
                    </Box>
                    {width > 850 &&
                        <Box
                            position={"absolute"}
                            top={25}
                            right={"-38%"}
                        >
                            <Typography
                                fontSize={12}
                                color={Colors.textPrimary}
                                mb={3}
                            >
                                Transfer to Evercash wallet
                            </Typography>

                            <Typography
                                fontSize={12}
                                color={Colors.textPrimary}
                            >
                                Instantly
                            </Typography>
                            <Typography
                                fontSize={12}
                                color={Colors.textSecondary}
                                mb={3}
                            >
                                Transfer term
                            </Typography>
                        </Box>
                    }
                </Paper>
                {width < 850 &&
                    <Paper
                        variant="outlined"
                        sx={{
                            ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                            padding: 3,
                        }}
                    >
                        <Typography
                            fontSize={12}
                            color={Colors.textPrimary}
                            mb={3}
                        >
                            Transfer to Evercash wallet
                        </Typography>

                        <Typography
                            fontSize={12}
                            color={Colors.textPrimary}
                        >
                            Instantly
                        </Typography>
                        <Typography
                            fontSize={12}
                            color={Colors.textSecondary}
                            mb={3}
                        >
                            Transfer term
                        </Typography>
                    </Paper>
                }
            </Stack>
            {/*EXCHANGE ORDER MODAL===============================================================================================*/}
            <Modal
                aria-labelledby="withdrawal-crypto-order-modal-title"
                aria-describedby="withdrawal-crypto-order-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade
                    in={openModal}
                >
                    <Box sx={ModalStyle}>
                        <Typography
                            id="withdrawal-crypto-order-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            {`Confirm transfer.`}
                        </Typography>
                        <Typography
                            id="exchange-order-modal-account-id"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`To account ID: ${dataTransfer.to}`}
                        </Typography>
                        <Typography
                            id="exchange-order-modal-order-id"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Amount: `} <span className={
                            dataTransfer.currency === "EPI"
                                ? "icon-eupi_symbol"
                                : dataTransfer.currency === "BTC"
                                    ? "icon-bitcoin_symbol"
                                    : dataTransfer.currency === "ETH"
                                        ? "icon-eth_symbol"
                                        : dataTransfer.currency === "EVER"
                                            ? "icon-ever_symbol"
                                        : dataTransfer.currency === "OLK" ? "icon-OILIK_symbol"
                                            : ""
                        } style={{fontSize: 15, fontFamily: "Icon"}}/> {`${dataTransfer.amount}`}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order--modal-title"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Note: ${dataTransfer.note}`}
                        </Typography>

                        <Button
                            variant="contained"
                            fullWidth
                            onClick={sendTransfer}
                        >
                            ok
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default Transfer;
/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {useActions} from "../hooks/useActions";
import {ibanValid, positiveNumberValid, swiftValid} from "../utils/validation";
import {useTypeSelector} from "../hooks/useTypeSelector";

import {
    Box,
    Button,
    Fade, InputAdornment,
    Modal,
    Paper,
    Snackbar,
    Stack, Tab, Tabs,
    TextField
} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Typography from "@mui/material/Typography";
import TitleMain from "../components/UI/TitleMain";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Loader from "../components/UI/Loader/Loader";
import {Colors, Fonts, ModalStyle, TextFieldStyle} from "../utils/constants";
import useWidthScreen from "../hooks/useWIdthScreen";

const Funds: FC = () => {
    const width = useWidthScreen();
    const search = window.location.search.substring(1);
    const [paymentRequest, setPaymentRequest] = useState(0);
    const {user} = useTypeSelector(state => state.user);
    const errorUser = useTypeSelector(state => state.user.error);
    const {dataCardDeposit, responseCardDeposit} = useTypeSelector(state => state.cardDeposit);
    const loadingCardDeposit = useTypeSelector(state => state.cardDeposit.loading);
    const errorCardDeposit = useTypeSelector(state => state.cardDeposit.error);
    const {error, responseFiatDepositInfo} = useTypeSelector(state => state.fiatDepositInfo);
    const {dataFiatWithdrawal, responseFiatWithdrawal, loading} = useTypeSelector(state => state.fiatWithdrawal);
    const errorFiatWithdrawal = useTypeSelector(state => state.fiatWithdrawal.error);
    const {responseFiatWithdrawalFee} = useTypeSelector(state => state.fiatWithdrawalFee);
    const errorFiatWithdrawalFee = useTypeSelector(state => state.fiatWithdrawalFee.error);
    const loadingFiatWithdrawalFee = useTypeSelector(state => state.fiatWithdrawalFee.loading);
    const {
        fetchCardDeposit,
        setCardDepositData,
        clearCardDepositData,
        fetchFiatDepositInfo,
        setFiatWithdrawalData,
        fetchFiatWithdrawal,
        fetchFiatWithdrawalFee,
        clearFiatWithdrawalFee,
        clearFiatWithdrawalData
    } = useActions();

    const [openSnackBar, setOpenSnackBar] = useState({
        text: "",
        open: false
    });

    const [value, setValue] = React.useState('1');

    const handleChangeCardDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "amount") {
            if (positiveNumberValid(event.target.value)) {
                setCardDepositData({...dataCardDeposit, amount: event.target.value})
            }
        }
    };

    const submitCardDeposit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (dataCardDeposit.amount > 0) {
            fetchCardDeposit(dataCardDeposit)
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "amount") {
            if (positiveNumberValid(event.target.value)) {
                setFiatWithdrawalData({...dataFiatWithdrawal, amount: event.target.value})
            }
        } else {
            setFiatWithdrawalData({...dataFiatWithdrawal, [event.target.name]: event.target.value})
        }
    };

    const submitWithdrawal = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (dataFiatWithdrawal.amount > 0 && swiftValid(dataFiatWithdrawal.swiftCode) && ibanValid(dataFiatWithdrawal.iban)) {
            fetchFiatWithdrawal(dataFiatWithdrawal)
        }
    };

    const handleChangeMethod = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar({
            text: "",
            open: false
        });
    };
    const handleOpenSnackBar = (text: string = "Copied to clipboard") => {
        setOpenSnackBar({...openSnackBar, text, open: true});
    };

    const handleCopy = (value: string) => {
        navigator.clipboard.writeText(value)
        handleOpenSnackBar();
    };

    useEffect(() => {
        clearCardDepositData();
        clearFiatWithdrawalFee();
        clearFiatWithdrawalData();
        fetchFiatDepositInfo();
        if (search.includes("amount")) {
            let params = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value)
            })
            setPaymentRequest(Number(params.amount));
        }
    }, []);

    useEffect(() => {
        if(paymentRequest > 0){
            setCardDepositData({...dataCardDeposit, amount: paymentRequest})
        }
    }, [paymentRequest]);

    useEffect(() => {
        if (dataFiatWithdrawal.amount > 0) {
            fetchFiatWithdrawalFee(dataFiatWithdrawal.amount);
        }
        if (dataFiatWithdrawal.amount <= 0) {
            clearFiatWithdrawalFee();
        }
    }, [dataFiatWithdrawal.amount]);

    useEffect(() => {
        if (responseFiatWithdrawal.status === 0) {
            clearFiatWithdrawalData();
            handleOpenSnackBar("Withdrawal status OK");
        }
    }, [responseFiatWithdrawal.status]);

    useEffect(() => {
        if (responseCardDeposit.status === 0) {
            window.open(responseCardDeposit.redirectUrl, "_blank");
        }
        clearCardDepositData();
    }, [responseCardDeposit.status]);


    if (error || errorUser || errorFiatWithdrawal || errorFiatWithdrawalFee || errorCardDeposit) {
        return <Typography>{error || errorUser || errorFiatWithdrawal || errorFiatWithdrawalFee || errorCardDeposit}</Typography>
    }

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
        >
            <Snackbar
                autoHideDuration={2000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={openSnackBar.open}
                onClose={handleCloseSnackBar}
                message={openSnackBar.text}
            />
            <TitleMain>
                TOP UP/WITHDRAWAL EUR/EUPI
            </TitleMain>
            <Typography
                maxWidth="fit-content"
                variant="h2"
                fontSize={18}
                fontWeight={700}
                marginBottom={3}
                color={Colors.textSecondary}
                sx={{
                    cursor: "pointer"
                }}
                onClick={() => {
                    navigator.clipboard.writeText(user.id ? user.id : "N/A")
                    handleOpenSnackBar("ID account copied to clipboard")
                }}
            >
                {`Your Evercash ID is: ${user.id} `}<ContentCopyOutlinedIcon sx={{fontSize: 18}}/>
            </Typography>
            <Typography
                maxWidth="fit-content"
                variant="h2"
                fontSize={18}
                fontWeight={700}
                marginBottom={3}
                color={Colors.textSecondary}
            >
                {`Your EuPI balance: `} <span className={"icon-eupi_symbol"} style={{
                fontSize: 15,
                fontFamily: "Icon"
            }}/> {user.balanceList.find(wallet => wallet.currency === "EPI" && wallet)?.balance}
            </Typography>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{
                        maxWidth: "100%",
                        bgcolor: 'background.paper'
                    }}>
                        <Tabs
                            value={value}
                            onChange={handleChangeMethod}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Card deposit" value="1"/>
                            <Tab label="SEPA deposit" value="2"/>
                            <Tab label="SWIFT deposit" value="3"/>
                            <Tab label="WITHDRAWAL" value="4"/>
                        </Tabs>
                    </Box>
                    {/*DEPOSIT CARD======================================================================================================*/}
                    <TabPanel
                        value="1"
                    >
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={0}
                        >
                            {loadingCardDeposit ?
                                <Loader/>
                                :
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                                        padding: 3,
                                    }}
                                >
                                    <Box
                                        component="form"
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                        noValidate
                                        onSubmit={submitCardDeposit}
                                        autoComplete="off"
                                    >

                                        <TextField
                                            fullWidth
                                            sx={TextFieldStyle}
                                            error={!dataCardDeposit.amount}
                                            helperText={!dataCardDeposit.amount && "Please enter amount."}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    €
                                                </InputAdornment>,
                                            }}
                                            placeholder={"0"}
                                            label="Amount"
                                            type="number"
                                            id={`amount`}
                                            name={`amount`}
                                            value={dataCardDeposit.amount}
                                            onChange={handleChangeCardDeposit}
                                        />
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{
                                                marginTop: 3
                                            }}
                                            type="submit"
                                            disabled={!(Number(dataCardDeposit.amount) > 0)}
                                        >
                                            Deposit
                                        </Button>
                                    </Box>
                                </Paper>
                            }
                        </Stack>
                    </TabPanel>
                    {/*DEPOSIT SEPA======================================================================================================*/}
                    <TabPanel
                        value="2"
                    >
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={0}
                        >
                            <Paper
                                variant="outlined"
                                sx={{
                                    ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                                    padding: 3,
                                }}
                            >
                                <Typography
                                    textAlign={"center"}
                                    variant={"h4"}
                                    fontWeight={400}
                                    color={Colors.textPrimary}
                                    fontSize={16}
                                    mb={3}
                                >
                                    SEPA transfer instruction
                                </Typography>
                                {
                                    paymentRequest > 0 &&
                                    <Typography
                                        textAlign={"left"}
                                        variant={"h4"}
                                        fontWeight={500}
                                        color={Colors.textInfo}
                                        fontSize={16}
                                        mb={3}
                                    >
                                        {`Payment request: €${paymentRequest}`}
                                    </Typography>
                                }
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    Payee name:
                                </Typography>

                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.sepa.name)}
                                >
                                    {responseFiatDepositInfo.sepa.name} <ContentCopyOutlinedIcon sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    IBAN:
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.sepa.iban)}
                                >
                                    {responseFiatDepositInfo.sepa.iban} <ContentCopyOutlinedIcon sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    Payment details:
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.sepa.details)}
                                >
                                    {responseFiatDepositInfo.sepa.details} <ContentCopyOutlinedIcon
                                    sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    Bank code:
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.sepa.bankSwift)}
                                >
                                    {responseFiatDepositInfo.sepa.bankSwift} <ContentCopyOutlinedIcon
                                    sx={{fontSize: 14}}/>
                                </Typography>
                            </Paper>
                        </Stack>
                    </TabPanel>
                    {/*DEPOSIT SWIFT=====================================================================================================*/}
                    <TabPanel value="3">
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={0}
                        >
                            <Paper
                                variant="outlined"
                                sx={{
                                    ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                                    padding: 3,
                                }}
                            >
                                <Typography
                                    textAlign={"center"}
                                    variant={"h4"}
                                    color={Colors.textPrimary}
                                    fontSize={16}
                                    mb={3}
                                >
                                    SWIFT transfer instruction
                                </Typography>
                                {
                                    paymentRequest > 0 &&
                                    <Typography
                                        textAlign={"left"}
                                        variant={"h4"}
                                        fontWeight={500}
                                        color={Colors.textInfo}
                                        fontSize={16}
                                        mb={3}
                                    >
                                        {`Payment request: €${paymentRequest}`}
                                    </Typography>
                                }
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    Payee name:
                                </Typography>

                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.swift.name)}
                                >
                                    {responseFiatDepositInfo.swift.name} <ContentCopyOutlinedIcon sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    IBAN:
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.swift.iban)}
                                >
                                    {responseFiatDepositInfo.swift.iban} <ContentCopyOutlinedIcon sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    Our bank address:
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.swift.bankAddress)}
                                >
                                    {responseFiatDepositInfo.swift.bankAddress} <ContentCopyOutlinedIcon
                                    sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    Payment details:
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.sepa.details)}
                                >
                                    {responseFiatDepositInfo.sepa.details} <ContentCopyOutlinedIcon
                                    sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    Bank code (BIC / SWIFT):
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.swift.bankSwift)}
                                >
                                    {responseFiatDepositInfo.swift.bankSwift} <ContentCopyOutlinedIcon
                                    sx={{fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textSecondary}
                                >
                                    BERTES INVEST address:
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    color={Colors.textPrimary}
                                    marginBottom={3}
                                    sx={{cursor: "pointer"}}
                                    onClick={handleCopy.bind(null, responseFiatDepositInfo.swift.address)}
                                >
                                    {responseFiatDepositInfo.swift.address} <ContentCopyOutlinedIcon
                                    sx={{fontSize: 14}}/>
                                </Typography>
                            </Paper>
                        </Stack>
                    </TabPanel>
                    {/*WITHDRAWAL========================================================================================================*/}
                    <TabPanel value="4">
                        {
                            loading
                                ? <Loader/>
                                :
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="stretch"
                                    spacing={0}
                                >
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            ...(width > 400 ? {minWidth: 460} : {minWidth: 300}),
                                            padding: 3,
                                        }}
                                    >
                                        <Box
                                            width="100%"
                                            component="form"
                                            display="flex"
                                            flexDirection="column"
                                            onSubmit={submitWithdrawal}
                                        >
                                            <TextField
                                                fullWidth
                                                sx={TextFieldStyle}
                                                error={!dataFiatWithdrawal.amount}
                                                helperText={!dataFiatWithdrawal.amount && "Please enter amount."}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">
                                                        €
                                                    </InputAdornment>,
                                                }}
                                                placeholder={"0"}
                                                label="Amount"
                                                type="number"
                                                id={`amount`}
                                                name={`amount`}
                                                value={dataFiatWithdrawal.amount}
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                fullWidth
                                                sx={TextFieldStyle}
                                                error={!ibanValid(dataFiatWithdrawal.iban)}
                                                helperText={!ibanValid(dataFiatWithdrawal.iban) && "IBAN no valid"}
                                                label="IBAN"
                                                type="text"
                                                id={`iban`}
                                                name={`iban`}
                                                value={dataFiatWithdrawal.iban}
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                fullWidth
                                                sx={TextFieldStyle}
                                                error={!swiftValid(dataFiatWithdrawal.swiftCode)}
                                                helperText={!swiftValid(dataFiatWithdrawal.swiftCode) && "BIC / SWIFT code no valid"}
                                                label="BIC / SWIFT"
                                                type="text"
                                                id={`swiftCode`}
                                                name={`swiftCode`}
                                                value={dataFiatWithdrawal.swiftCode}
                                                onChange={handleChange}
                                            />
                                            <Typography
                                                color={Colors.textPrimary}
                                                mb={3}
                                            >
                                                Fee:
                                                € {loadingFiatWithdrawalFee ? "calc..." : responseFiatWithdrawalFee}
                                            </Typography>
                                            <Typography
                                                color={Colors.textPrimary}
                                                mb={3}
                                            >
                                                Total:
                                                € {loadingFiatWithdrawalFee ? "calc..." : responseFiatWithdrawalFee + Number(dataFiatWithdrawal.amount)}
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    marginTop: 3
                                                }}
                                                type="submit"
                                                disabled={!(dataFiatWithdrawal.amount > 0 && swiftValid(dataFiatWithdrawal.swiftCode) && ibanValid(dataFiatWithdrawal.iban))}
                                            >
                                                send
                                            </Button>
                                        </Box>
                                    </Paper>
                                </Stack>
                        }
                    </TabPanel>
                </TabContext>
            </Box>
            {/*WITHDRAWAL FIAT ORDER MODAL=======================================================================================*/}
            <Modal
                aria-labelledby="withdrawal-crypto-order-modal-title"
                aria-describedby="withdrawal-crypto-order-modal-description"
                open={false}
                // onClose={handleOpenModal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade
                    // in={openModalOrder}
                >
                    <Box sx={ModalStyle}>
                        <Typography
                            id="withdrawal-crypto-order-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            {/*{`Exchange ${dataExchange.currency} order.`}*/}
                        </Typography>
                        <Typography
                            id="exchange-order-modal-account-id"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {/*{`Account ID: ${responseExchange.accountId}`}*/}
                        </Typography>
                        <Typography
                            id="exchange-order-modal-order-id"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {/*{`Order ID: ${responseExchange.id}`}*/}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order-modal-sent"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Amount: `}
                            <span className={"icon-eupi_symbol"
                                // dataExchange.currency === "EPI"
                                //     ? "icon-eupi_symbol"
                                //     : dataExchange.currency === "BTC"
                                //         ? "icon-bitcoin_symbol"
                                //         : dataExchange.currency === "ETH"
                                //             ? "icon-icon-eth_symbol"
                                //             : dataExchange.currency === "EVER" ? "icon-ever_symbol"
                                //                 : ""
                            } style={{fontSize: 12, fontFamily: "Icon"}}/>
                            {/*{responseExchange.amount}*/}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order--modal-title"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {/*{`Operation: ${responseExchange.operation}`}*/}
                        </Typography>

                        <Button
                            fullWidth
                            // onClick={handleCloseModal}
                        >
                            ok
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default Funds;